.detail-box .font {
    font-size: 16px;
}

.hidden_class {
    display: none;
}

.header .heading {
    font-weight: normal;
    font-size: 16px;
}

.header .heading p.username {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 8px;
}

.header .heading p {
    margin: 2px 19px 0 0;
}

.like-message_show img {
    width: 45px;
    height: 45px;
    margin-top: 15px;
}

.like-message_show p {
    opacity: 0.7;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    margin-bottom: 0px;
}

.feedback-show .tip-bar, .feedback-show .heading {
    display: none;
}

.feedback-show {
    /* position: relative;
    padding: 0px; */
}

.thumb, .submit-btn1, .Btn2, .skip-submit .skip-btn, .skip-submit .done-btn {
    cursor: pointer;
}

.feedback-show h2 {
    opacity: 0.7;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-top: 75px;
}

.skip-submit {
    /* position: fixed; */
    background-color: #fff;
    width: 100%;
    /* bottom: 0; */
    z-index: 1;
    /* height: 70px; */
}

.feedback-show .feedback-para {
    margin-bottom: 115px;
    height: 171px;
    border-radius: 4px;
    border: solid 1px lightgray;
    outline: none;
    padding-top: 15px;
    font-family: Poppins, sans-serif;
}

.skip-submit .done-btn {
    margin-left: 27px;
    border-radius: 5px;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    border-color: #ffffff;
    outline: none;
    padding: 0px;
}

.skip-submit .skip-btn {
    border-radius: 5px;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    width: 140px;
    height: 43px;
    border-radius: 5px;
    margin-left: 27px;
    background-color: #ffffff;
    outline: none;
}

.skip-submit.fa .skip-btn {
    color: #199100;
    border: solid 1px #199100;
}

.skip-submit.bk .skip-btn {
    color: #ff5f5d;
    border: solid 1px #ff5f5d;
}

.skip-submit.fa .done-btn {
    background-color: #199100;
    border: solid 1px #199100;
}

.skip-submit.bk .done-btn {
    background-color: #ff5f5d;
    border: solid 1px #ff5f5d;
}

.thankyou-image_content {
    padding: 20px;
}

.thankyou-image {
    text-align: center;
    margin: 80px 38px 58px;
}

.thankyou-image img {
    max-width: 100%;
    height: auto;
}

.thankyou-content {
    opacity: 0.7;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1c2120;
}

.btn-botom {
    padding: 0px;
}

.continue-shopping {
    text-align: center;
    width: 85%;
    margin: 20px auto 20px;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 3;
    letter-spacing: normal;
    color: #ffffff;
    text-transform: uppercase;
}

.font-title {
    opacity: 0.7;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #1c2120;
}

.feedback-show h2.dislike-heading {
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #1c2120;
    margin-left: 10px;
    margin-right: 10px;
}

.detail-box .parameter_content {
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 0px;
}

.two-button_set {
    margin: 20px auto;
}

@media only screen and (max-width:767px){
    .skip-submit {
        position: fixed;
        bottom: 0;
    }
    .skip-submit .done-btn, .skip-submit .skip-btn {
        margin-left: 0px;
    }
    .skip-submit .two-button_set {
        /* margin: 33px auto -26px; */
        display: flex;
        justify-content: space-around;
    }
}
@media only screen and (max-width:325px) {
    .feedback-show .feedback-para {
        margin-bottom: 50px;
    }
}
