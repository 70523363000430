@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600&display=swap);
body {
  margin: 0;
  font-family: Lato;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body{
  margin: 0;
  padding: 0;
}

.container
{
 width: 360px;
 margin: 0 auto;
 position: relative;
 overflow-x: hidden;
 }

 .container.container-revamp {
  background: #F7FAFB;
  padding-bottom: 0px;
}

 .detail-contentInner {
  border-radius: 24px;
  background: #FFF;
  box-shadow: 0px -3px 16px 0px rgba(140, 140, 140, 0.10);
  display: flex;
  padding: 48px 24px 56px 24px;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
  grid-gap: 108px;
  gap: 108px;
  position: relative;
 }

 .detail-contentInner .detail-box {
  margin-bottom: 0px;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  align-self: stretch; */
 }
 
 .detail-contentInner .detail-box h2 {
  color: #197889;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.8px;
  margin: 0px 0px 20px;
  text-align: left;
 }

 .detail-contentInner .detail-box em {
  border-radius: 5px;
  background: rgba(28, 33, 32, 0.10);
  position: relative;
  top: -40px;
  width: 40px;
  height: 3px;
  display: block;
  margin: auto;
 }

 [data-rsbs-overlay] {
  max-width: 100% !important;
  margin: auto !important;
 }

.logo
{
  max-width: 45%;
  margin-top:35px;
}

.new_header.header {
  padding: 100px 0px;
}

.new_header .logo, .new_header .heading {
  margin-top: 0px;
  max-width: 100%;
}

.new_header .heading {
  display: none;
}

.heading
{
  padding-left:5px ;
  text-align: left;
  margin-top: 40px;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-weight: 600;
  color: #1c2120;
}

.heading p
{
margin: 0;
padding: 0;
margin-bottom: 8px;
}

.product-heading
{
  padding-left:15px ;
  text-align: left;
  margin-top: 40px;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  color: #1c2120

}
.flow
{
  overflow: auto;
}

.font
{
padding:15px ;
/* opacity: 0.7; */
font-family: Poppins, sans-serif;
font-size: 17px;
font-weight: normal;
font-style: normal;
font-stretch: normal;
line-height: 1.25;
letter-spacing: normal;
text-align: center;
color: #1c2120;
}

.detail-contentInner .font {
  padding: 0px;
  margin: 0px 0px 45px;
  text-align: left;
  color: #1C2120;
  font-family: Poppins, sans-serif;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.detail-contentInner .font.font-product, .detail-contentInner .font.font-feedback {
  font-size: 18px;
  margin-bottom: 34px;
}

.btn-revamp .adjustButton {
  margin-top: -58px !important;
}

.review-like-revamp textarea:focus-visible, .review-like-email input:focus-visible {
  outline: none;
}

.font-btn
{
margin-top: 20px;
padding-left:15px ;
font-family: Poppins, sans-serif;
font-size: 16px;
line-height: 1.25;
letter-spacing: normal;
text-align: left;
color: #1C2120;
font-weight: 500;
}
.More-heading, .feedback-para {
  margin-left: 10px;
}

.flow.dislikeContent {
  padding: 48px 24px 56px 24px;
  grid-gap: 36px;
  gap: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width:1024px){
 .container{
     width:100%;
     height: auto;
     padding-bottom: 100px;

 }
 .btn-background.btn-revamp {
  position: static;
  padding: 0px;
 }
 .btn-background {
    background-color: #fff;
    width: 100%;
    position: fixed;
    bottom: 0;
    padding: 9px 0px;

  }


}



 .ml{
   margin-left: 10px;
 }


.header
{
 text-align: center;
 padding-left: 10px;
}

.Group-img {
  position:absolute;
  margin-left: 23px;
  max-width:100%;
  z-index: -1;
}
.tip-bar
{
 position: absolute;
 right: 15px;
 top:3px;
 width: 54px;
 padding-top: 45px;
}

.tip-bar p{
 display: inline-block;
 font-size: 16px;
 margin-top: 5px;
}

.new_header .tip-bar {
  width: auto;
  padding-top: 30px;
  opacity: 0.5;
  display: flex;
  align-items: center;
  top: 0px;
  z-index: 2;
}

.new_header .tip-bar p {
  color: #000;
  font-family: Poppins, sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  margin-top: 0px;
  margin-bottom: 0px;
}
 
.new_header .tip-bar .bulb {
  width: 14px;
}


.bulb
{
 padding:5px;
 width: 15px;
 float: left;
}

.progress-bar
{
    width: 100%;
    height: 15px;
    background-color: #edf1f7;
    margin-top: 30px;


}
.progress-bar1
{
    width: 100%;
    height: 15px;
    background-color: #edf1f7;
    margin-top: 10px;

}

.line
{
    width: 100%;
    height: 2px;
    background-color: #edf1f7;
    margin: 0 auto;
    margin-top: 30px;
}

.hrline1
{
    width: 95%;
    height: 1px;
    background-color: #edf1f7;
    margin: 0 auto;


}


.hrline2
{
    width: 95%;
    /* height: 1px;
    background-color: #edf1f7; */
    margin: 0 auto;
    margin-top: 12px;
    border-top: 1px solid #E7EDEF;

}


.detail-box
{
 text-align: center;
 margin-bottom: 75px;
 }

.arrow
{
  width: 24px;
  float: left;
  height: 24px;
  object-fit: contain;
  margin-top: 45px;
}

.review-button
{
 display: none;
}
.first{
  margin-right:30px;
}
.Btn1:focus,.Btn2:focus{
  outline: none;
}
.Btn1
{
 background:  white;
 border-radius: 5px;
 padding:10px;
 text-align: center;
 float:left;
 margin-left: 10px;
 margin-top: 20px;
 padding-right: 5px;
 border: 1px solid #199100;
 color: #199100;
 font-family: Poppins, sans-serif;
}

.flow.negativetagsBK .Btn1 {
  color: #ff5f5d;
  border-color: #ff5f5d;
  border: 1px solid #ff5f5d ;
}

.flow.negativetagsFA .Btn1 {
  color:#199100;
  border-color: #199100;
  border: 1px solid #199100;
}

.Btn2
{
 background:  white;
 border-radius: 5px;
 padding: 10px;
 text-align: center;
 border: 1px solid #1C2120;
 color: #1C2120;
 float: left;
 margin-left: 10px;
 margin-top: 20px;
 padding-right: 5px;
 font-family: Poppins, sans-serif;

}

.dislikeContent p {
  margin: 0px;
}
.dislikeButton em, .gift-view em {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEmSURBVHgBrVWLjcIwDHWq24NscGxwGeE6wXWTYxSYoGwQRoAJ0k3Ke8JFITiFolp6aurPk52+pCIrm5sLjuPo8QiAV9cZGJxz51pNUyHqgIgl8ZOF/oAescQceWXsiESKMJMXlDTqFFUyJu3kTWOu1ngrGJeQFaSxdHLPknxo2kyXO1K5Z3jfEkbxk1/3NOYJqVKY8mLLVzS14eIXOIo9yp1gjkxz9xz7C2smmEKlgJHUYtmrq50R9QD4Rla2Rpk3VlDHY3etoq+NDPsWTjoJ2iL74KNsp5e4gmxS7uie1L7AzMtCu/yXhcYas5nscnibVMnsyyEjjdZRLPJCds09kLlKQYfH1CmFfNE15RXkJrUDRL4va1/9Avg1vdxOkyjRCURDreYKNxZucvhPsBUAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-position: 4px center, 90% center;
  padding: 5px 10px 5px 10px;
  background-size: 16px;
}
.gift-view em {
  padding: 5px 10px;
}
.dislikeContent .dislikeButton .Btn1, .gift-view .giftbtngreen {
  border: 1px solid #197889;
  background: #197889;
  color: #FFF;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.24px;
}
.dislikeContent .dislikeButton .Btn2, .dislikeContent .dislikeButton .Btn1 {
  margin-left: 0px;
  margin-top: 0px;
  margin-bottom: 15px;
  margin-right: 15px;
  border-radius: 50px;
  float: none;
  font-size: 11px;
}
.dislikeContent .dislikeButton .Btn2 {
  padding: 6px 12px;
}
.dislikeContent .dislikeButton .Btn1 {
  padding: 6px 5px 6px 8px;
}
.dislikeContent .dislikeButton .Btn2:nth-child(2), .dislikeContent .dislikeButton .Btn1:nth-child(2) {
  margin-right: 0px;
}
/* .dislikeContent .dislikeButton .Btn2:nth-child(1), 
.dislikeContent .dislikeButton .Btn1:nth-child(1), 
.dislikeContent .dislikeButton .Btn1:nth-child(2), 
.dislikeContent .dislikeButton .Btn2:nth-child(2) {
  margin-top: 0px;
} */
.dislikeContent .btn-background.btn-revamp {
  margin-top: -15px;
  width: 100%;
}

.giftbtn:focus,.giftbtngreen:focus{
  outline: none
}
.giftbtngreen
{
 /* background:  white; */
 border-radius: 50px;
 /* padding:10px; */
 text-align: center;
 /* float:left; */
 padding: 6px 10px;
    margin-top: 16px;
 /* color:#199100; */
 /* border-color: #199100;
 border: 1px solid #199100; */
 margin-right: 10px;
 /* margin-bottom: 10px; */
 font-size: 12px !important;
 /* padding-right: 5px; */
}
.giftbtnbk
{
 background:  white;
 border-radius: 4px;
 padding:10px;
 text-align: center;
 float:left;
 color:#ff5f5d;
 border-color: #ff5f5d;
 border: 1px solid #ff5f5d;
 margin-left: 10px;
 margin-bottom: 10px;
 font-size: 15px;
 padding-right: 5px;
}
.giftbtn
{
 background:  #F4F7F8;
 /* border-radius: 4px; */
 padding: 6px 12px;
 text-align: center;
 margin-top: 16px;
 color: #1C2120;
 /* border: 1px solid rgba(28,33,32 ,0.1); */
 /* float: left; */
 margin-right: 10px;
 /* padding-right: 5px; */
 font-size: 12px;
 border-radius: 50px;
 border: 1px solid rgba(134, 134, 134, 0.80);
 letter-spacing: 0.24px;
}


.submit-btn1
{

     background: rgb(175, 172, 172,0.5);
     color: #FFF;
     border-radius: 5px;
     box-shadow: inset 0 1px 0 rgba(233, 240, 233, 0.2);
     font-family: Arial, sans-serif;
     line-height: 2.5em;
      text-decoration: none;
      width: 85%;
      margin: 20px auto 20px;;
      border: 0;
      height:50px;
      font-size: 18px;
      display: block;
      z-index: 1;
}

.btn-revamp .submit-btn1 {
  margin: auto;
  width: 100%;
  border-radius: 40px;
  background: rgba(18, 77, 100, 0.30);
}

.review-box
{
 overflow: auto;
 display: inline;

}

.review-like-email {
  position: relative;
}

span
{
padding-left: 25px;
padding-bottom: 20px;
}
.margin
{
  margin-bottom: 40px;
}

.circle
{
 padding-bottom: 20px;
 width: 69px;
 height: 20px;
 border-radius: 6px;
 border: 1px solid rgb(175, 172, 172);
 display:inline-block;
}


.review-like-revamp .circle {
  border: none;
  padding-bottom: 0px;
  height: auto;
  width: auto;
  background-color: #fff;
}

.review-like-revamp .circle.first {
  margin-right: 60px;
}

.review-like-revamp .circle .thumb {
  width: 100px;
  height: 100px;
  padding: 0px;
}

.review-box.review-like-revamp.fa .active {
  background-color: #fff !important;
}

.review-like-revamp .circle span {
  display: block;
  color: #1C2120;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28.8px;
  margin-top: 20px;
  padding: 0px;
}

.btn-background.btn-revamp.fa .btnactive {
  background-color: #124D64 !important;
}



.active{
 background-color: #ff5f5d !important;

}
.review-box.bk .first.active{
  background-color: #ff5f5d !important;
 }
.review-box.bk .active{
  background-color: #199100 !important;
 }
.review-box.fa .active {
  background-color: #199100 !important;
}
.inactive{
 background-color:white !important;
 }

.btnactive.bk, .btn-background.bk .btnactive {
 background-color: #ff5f5d !important;

}
.btninactive{
 background-color:grey!important;

}

.btnactive.fa, .btn-background.fa .btnactive {
  background-color: #199100 !important;
}



.thumb
{

 padding:5px;
 width: 30px;
}
.overlay-box{
 position: absolute;
 background-color: rgba(0,0,0,0.5);
 width: 100%;
 bottom: 0;
 height: 100%;
 left: 0;
 z-index: 1;
}
.overlay-loader{
 position: fixed;
 background-color: rgba(0,0,0,0.5);
 width: 100%;
 bottom: 0;
 height: 100%;
 left: 0;
 z-index: 1;
 opacity: 0.5
}

.description
{
 font-size: 15px;
 position: fixed;
 bottom: 0;
 width: 100%;
 background: #fff;
 padding: 10px;
 box-sizing: border-box;
 left: 0;
 z-index: 2;
 font-family: Poppins, sans-serif;

}

.More-Feedback
{

 position: fixed;
 bottom: 0;
 width: 100%;
 background: #fff;
 /* padding: 10px; */
 box-sizing: border-box;
 left: 0;
 z-index: 2;

}

.detail-contentInner.revamp-feedback {
  position: static;
  padding: 0px;
}

.revamp-feedback .lottify-check {
  background-image: url(/static/media/lottify.00e2a2ba.gif);
  background-repeat: no-repeat;
  background-size: 350px;
  margin-bottom: 56px;
  padding: 48px 24px 0px 24px;
}

.More-heading {
  font-family: Poppins, sans-serif;
  font-size: 28px;
  font-weight: 600;
  /* text-align: center; */
  color: #1c2120;


}

.description-active
{
 display:block;
}

.hr
{
width:95%;
margin: 0 auto;

}
.giftwrapper
{
 /* width: 100%; */
  margin: 0 auto;
  position: relative;
  padding-top:10px;
  transition-duration: 2s;
  padding: 16px;
  background: #F4F7F8;
  margin-bottom: 20px;
  border-radius: 10px;
}

.gift-container .giftwrapper:last-child {
  margin-bottom: 0px;
}

.addproduct
{

 border-color: #edf1f7;
 float:left;
 width: 60px;
 height: 60px;
 border-style: double;
 border-radius:6px;
}

.giftbox1
{
 width: 50%;
 margin: 0 auto;
 position: relative;
 display: inline-block;
}
.giftbox2
{
 width: 50%;
 margin: 0 auto;
 position: relative;
 display: inline-block;
}


.detail-contentInner .gift-container {
  margin-top: -108px;
  /* border-radius: 10px;
  background: #F4F7F8; */
  /* padding: 16px; */
  /* position: relative; */
}

.detail-contentInner .giftwrapper.border-enable {
  border: 1px solid #197889;
}

.giftwrapper .giftbox {
  display: flex;
  align-items: center;
}

.textsize
{
  padding-left: 15px;
  /* display:inline-block; */
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: normal;
  color: #1c2120;
  /* height: 0; */
  /* margin-top: 15px; */
  margin: 0px;
  padding-bottom: 3.5px;
  width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.giftwrapper .disLikeCount {
  position: absolute;
  top: 0px;
  right: 0px;
  border-radius: 0px 10px 0px 5px;
  background: #197889;
  padding: 5px 10px;
  color: #FFF;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; 
}

.delivered{

font-family: Poppins, sans-serif;
font-size: 14px;
font-weight: normal;
/* font-style: italic; */
letter-spacing: normal;
color: rgba(28, 33, 32, 0.50);
padding-left: 15px;
padding-top: 3.5px;
margin: 0px;
}
.left{
  margin-left: -25px;
}

.bottom
{
margin-bottom: 40px;
}

.gift-button button
{
  background:  white;
 border-radius: 4px;
 padding: 10px;
 text-align: center;
 color:gray;
 float: left;
 margin-left: 12px;
}

.giftimage
{
position:absolute;
font-size: 14px;
margin-right: 5px;
right: 8px;
bottom: 2px;
}
.link-col
{
color: #199100;
}

.btn-revamp .link-col {
  margin: auto;
  width: 100%;
  border-radius: 40px;
  background-color: #124D64;
  color: #FFF;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  height: 50px;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-text-decoration: auto;
          text-decoration: auto;
}

.feedback.btn-revamp {
  width: 100%;
  margin: 108px 0px 0px;
}

.feedback
{
 width: 95%;
 margin: 0 auto;
 text-align: center;
 font-size: 14px;
 position: relative;
 margin-bottom: 50px ;
 color: #199100;
 margin-top: 20px;
}
.feedback-container
{
 width: 320px;
 margin: 0 auto;
 border: 1px solid black;
 position: relative;
 height:450px;
 }
.feedback-header
{
 height: 40px;
 width:100%;
 margin:0;
 padding-right:10px;
 text-align: center;
}

.cross
{
  width: 18px;
  height: 20px;
 float: right;
 padding-right: 10px;
}

.feedback-para
{
padding:10px;
border: solid 1px grey;
font-size: 14px;
color: gray;
/* margin: 10px; */
padding-block:0px;
margin-bottom: 50px;
height: 80px;
width:87%;
border-radius: 5px;
}

.add{

 position: absolute;
 background-position-y: 5px;
 /* padding:5px; */
 width: 24px;
 right: 16px;
 top: 40px;
}

.addchange{

  position: absolute;
  background-position-y: 5px;
  padding:5px;
  width: 12px;
  right: 10px;
  top:20px;
 }
.addclass{
 width:40px;
 height: 40px;
 background-size: 30px;
 float: right;
 background-position-y: 5px;

}

.top{
  padding-bottom: 20px;
}
.check{
 width:40px;
 height: 40px;
 background-size: 30px;
 float: right;
 background-position-y: 5px;
 background-repeat: no-repeat;
 display: none;
}


.feedback-para:focus{
 border:1px solid red;
}

.done-btn
{
 background-color:white;
 color: rgb(17, 90, 9);
 box-shadow: inset 0 1px 0 rgba(17, 112, 22, 0.2);
 font-family: Poppins, sans-serif;
 padding: 0 3em;
 width: 35%;
 width: 140px;
 height: 43px;
 border-radius: 5px;
 margin-left: 100px;
 /* margin-bottom: 20px; */
 font-size: 16px;
}
.rectangle
{
  /* width: 50px;
  height: 50px;
  background-color: #199100;
  border-radius: 50px; */
  display: block;
  margin-left: auto;
  margin-right: auto;
}


.path
{
 /* padding:15px; */
 width: 43px;
 /* padding-left: 10px; */
}
.Thanks-text {


  font-family: Poppins, sans-serif;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #1c2120;
  margin: 0px;
}

.thankyouFont {
  color: #1C2120;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  margin-top: 24px;
  margin-bottom: 0px;
}

.thankyouFont span, .emailFont span {
  color: #197889;
  padding: 0px;
  background-image: url(/static/media/underLine.b96aeb7f.svg);
  background-repeat: no-repeat;
  background-position: 0px 28px;
  display: inline-block;
}

.emailFont span {
  background-image: url(/static/media/emailUnderLine.ebc1ceb5.svg);
}

.edit-image
{
 font-size: 15px;
 position: absolute;
 bottom: 0;
 width: 100%;
 background: #fff;
 padding: 10px;
 box-sizing: border-box;
 display: none;
 z-index: 2;

}
.text-right
{
float: right;
}
.text-left
{
float: left;
}


.edit-box
{

 padding-block:0px;
 border: 1px solid black;
 margin-bottom: 25px;
 height: 150px;
 clear: both;
}


.pen
{
 top:30px;
 padding:5px;
 width: 30px;
 float: right;
}

.loader {
position:absolute;
left:50%;
top:50%;
z-index: 2;
transform:translate(-50%,-50%);
}
.downward{
  width: 24px;
}

.email-input {
  height: 50px;
  width: 70%;
  border-radius: 10px;
  border: 1px solid rgba(28, 33, 32, 0.50);
  padding-left: 46px;
  font-size: 16px;
  font-family: Poppins, sans-serif;
  color: #1C2120;
  padding-right: 46px;
}

.validErroMessage {
  color: #FF4C4C;
  font-family: Poppins, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px;
  margin-top: 10px;
  margin-bottom: 0px;
  text-align: left;
}

.review-like-email .emailIcon {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHKSURBVHgB7ZRNSAJREMefsSBiSV2iDYQ6GERfhy7RIYoOEbQRRRfDWCg8B0VFJCsRQql01qNSew0MJBAzDC9lpN662CKoBZLYweM2Ewm2+bGZlAd/8HizM7P735l9O4Q0aTQUUocoiu2wLZDayMJ6UCgUT2UzQIAT68NGyUog0ANbPJ/PP1qOLZukBuguum2NXbMrlUoaLjugoqxUZBK2K7RzuVzYemI1u9yulFwBw4qhe3tzm9NoNKOfrikQCaDRIk1OP6c9arW6b39332k7so3KEcA8057JgfdlMpmANP5NJHgTvOAOuBW0l5eWHT6vz1hJAOOYh7b50Kz3B/x8VRHEfepOsiyrFwSB1+l0xth9zLFqWKWLc7A96Me4kBB4dp3Vl2svRcoQug29TUxP2D3nnuRA/4AR2zc7M+v0XnrvhgeHu+fn5s0URbVGo1E7s8jwpAIUqQKzwPDw1tfY8/GxcQ7Whx9PIbTHKOdwVBVBsH2wGPzAWq2WTiQSqa2drTCRiSyRAj95cDEt5A/4HxH4Y9tInSkeKzh9cXa9RGKRM1IjI0MjepVK1Qlmb2F2fQGEWFhx8Xe84nNKViIR6yG1ky1ZQZOG4h1a6zprt9MdBAAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  position: absolute;
  margin-top: 13px;
  width: 24px;
  display: inline-block;
  margin-left: 18px;
}

.review-like-email .signTick {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKySURBVHgBvVYtbNtQEP7sNOkkT5s6EEtjKW7xilewPyloJGQoZNK2dGCk4yEBbVZQEm7egY6ELzghJRnb5tLNUhs17/W7Zzt1HDtxSE/K3/PdfXff/bwA9yBWUUX3FFVM+NJwaBXARuB/xK8itktB3A6c0ibqSqFOTWfBWOOS50M1ged/4fd1Qdxj1PmwIc61xpBHA34fSwZQBLSZ1RR7loU90bcteL8/wSsEItHbmzik410N9LGFnv+OjvOCCWls0NE+AX+qa3SZ1Zz+RtrILqNlADSdH+AMK8R/b2jqMnOpT5MBirSTOqW5qI5Ij41XRQGSEvzAhfPSZFB33iAIznExC3wG0EHVstEQitYFiEXsNCmzFBpC+wIIids3n5Xs4uWJ28XO3EEZPb6brlwAYXdIpwwjjosBHJmCt90T1OIzsWc2Q62ioGMQSY0PapyHwVoApFdad2Eo6Yf+qjFlYSaVKBKZg7SzNB2IZsg2WfQzZ2MasfGA7Y1kTbKiJQ1Cx9Nv7Lr4rMMzC02CjP+00M003MD/KCMn/BlCyRTD7KWECA2kxVOMmkCYXqNvldEm8KV6jK/Ikxs8TE5gmMnVrNhuWt//DE9oUZr0lNFlFoGq4HDZFqDXkP5JWCsDImtAOsK28SzLRmgh0FiWkAFY1YEl7Ih+vF7uajLFSNZJVqEjoAO9heYqADPU9EP2v98lFsuNmfKALdnIc7CUotghM5WasWOHCyCGMg1Pskl20zois8O1tM0svGTGcwvSLLkX5uZ7++g18O8cIxSU5HD+bc3vPmuZgdx8ysKx38oHkxoKxcIAnZ2xdr20Tv7NeILntjbGruksYMS1M9sI7MRtWtfEOX8GusRgPmSvpZV/JASMnSe33m7qkdRQQAd4wuthSVMU/rdiAE/DXSSyzra+F7kFs9MCElAxubQAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  position: absolute;
  width: 15px;
  top: 0px;
  right: 0px;
  padding-bottom: 25px;
}

.review-like-revamp .detail-feedback {
  width: 88%;
}
.detail-feedback {
  height: 67px;
  width: 77%;
  border-radius: 10px;
  border: 1px solid rgba(28, 33, 32, 0.20);
  padding: 17px;
  font-size: 16px;
  font-family: Poppins, sans-serif;
}

.font-feedback{
  margin-top: 0px;
  padding-top: 27px;
}
/* 
.gift-container{
  margin-bottom: 50px;
} */
.detail-box .font {
    font-size: 16px;
}

.hidden_class {
    display: none;
}

.header .heading {
    font-weight: normal;
    font-size: 16px;
}

.header .heading p.username {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 8px;
}

.header .heading p {
    margin: 2px 19px 0 0;
}

.like-message_show img {
    width: 45px;
    height: 45px;
    margin-top: 15px;
}

.like-message_show p {
    opacity: 0.7;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    margin-bottom: 0px;
}

.feedback-show .tip-bar, .feedback-show .heading {
    display: none;
}

.feedback-show {
    /* position: relative;
    padding: 0px; */
}

.thumb, .submit-btn1, .Btn2, .skip-submit .skip-btn, .skip-submit .done-btn {
    cursor: pointer;
}

.feedback-show h2 {
    opacity: 0.7;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-top: 75px;
}

.skip-submit {
    /* position: fixed; */
    background-color: #fff;
    width: 100%;
    /* bottom: 0; */
    z-index: 1;
    /* height: 70px; */
}

.feedback-show .feedback-para {
    margin-bottom: 115px;
    height: 171px;
    border-radius: 4px;
    border: solid 1px lightgray;
    outline: none;
    padding-top: 15px;
    font-family: Poppins, sans-serif;
}

.skip-submit .done-btn {
    margin-left: 27px;
    border-radius: 5px;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    border-color: #ffffff;
    outline: none;
    padding: 0px;
}

.skip-submit .skip-btn {
    border-radius: 5px;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    width: 140px;
    height: 43px;
    border-radius: 5px;
    margin-left: 27px;
    background-color: #ffffff;
    outline: none;
}

.skip-submit.fa .skip-btn {
    color: #199100;
    border: solid 1px #199100;
}

.skip-submit.bk .skip-btn {
    color: #ff5f5d;
    border: solid 1px #ff5f5d;
}

.skip-submit.fa .done-btn {
    background-color: #199100;
    border: solid 1px #199100;
}

.skip-submit.bk .done-btn {
    background-color: #ff5f5d;
    border: solid 1px #ff5f5d;
}

.thankyou-image_content {
    padding: 20px;
}

.thankyou-image {
    text-align: center;
    margin: 80px 38px 58px;
}

.thankyou-image img {
    max-width: 100%;
    height: auto;
}

.thankyou-content {
    opacity: 0.7;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1c2120;
}

.btn-botom {
    padding: 0px;
}

.continue-shopping {
    text-align: center;
    width: 85%;
    margin: 20px auto 20px;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 3;
    letter-spacing: normal;
    color: #ffffff;
    text-transform: uppercase;
}

.font-title {
    opacity: 0.7;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #1c2120;
}

.feedback-show h2.dislike-heading {
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #1c2120;
    margin-left: 10px;
    margin-right: 10px;
}

.detail-box .parameter_content {
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 0px;
}

.two-button_set {
    margin: 20px auto;
}

@media only screen and (max-width:767px){
    .skip-submit {
        position: fixed;
        bottom: 0;
    }
    .skip-submit .done-btn, .skip-submit .skip-btn {
        margin-left: 0px;
    }
    .skip-submit .two-button_set {
        /* margin: 33px auto -26px; */
        display: flex;
        justify-content: space-around;
    }
}
@media only screen and (max-width:325px) {
    .feedback-show .feedback-para {
        margin-bottom: 50px;
    }
}

